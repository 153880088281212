import React, { Component } from "react";
import bonus from "../../assets/img/pictures/bonus1.png";


class HomeBonus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (

      <section className="bonus">
        <div className="container">
          <h2>You will also receive these 2 free bonuses when attending</h2>
          <div className="bonuses">
            <div className='image'><img src={bonus} alt="" /></div>
            <div className='text'>
              <div className='block'>
                <h2>Free gift bonus #1</h2><h3>Proven Strategies for {this.state.exam} Success</h3>
                <p>How to improve your study strategies, organise time effectively and beat procrastination. It's our proven strategies for {this.state.exam} success.</p>
              </div>
              <div className='block'>
                <h2>Free gift bonus #2</h2><h3>Memory Strategies proven to achieve A+ results</h3>
                <p>You will learn additional memory strategies to help you memorise course material and recall vital information for exams.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="reserve-section">
          <a href="#intro-form" className="reserve-orange">
            Book Your Place now
              </a>
        </div>
      </section>

    );
  }
}
export default HomeBonus;

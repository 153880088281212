import React, { Component } from "react";
import RegistrationForm from "./RegistrationForm";

class HomeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (
      <div id="home-hero" className="top-hero">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="intro-blurb xhidden-sm">
                <h3 className="subheading">
                  For Students in Year 9, 10, 11 and 12
                </h3>
                <h2>
                  In 60 minutes, discover the {this.state.exam} success strategies that have
                  helped 63% of our students score an ATAR of 90 and above.
                </h2>
                <p>
                  Memory guru and learning strategies expert, Dan Dobos, shares
                  the key study strategies that helped him go from scoring B's
                  and C's in Years 10 and 11, to scoring a final ATAR of 99.25
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <RegistrationForm  {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeHero;

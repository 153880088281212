import request from "./axios";

function register(params) {
  return request({
    method: "post",
    url: `/seminar_registrations/register`,
    data: { seminar_registration: params }
  })
}

function openSeminars(region, m1_booking_user) {
  let url = `/seminars/open_seminars?region=${region}`
  if (m1_booking_user) url += `&m1_booking_user=${m1_booking_user}`
  return request({
    method: "get",
    url: url
  })
}

const SeminarsService = {
  openSeminars, register
};

export default SeminarsService;

import queryString from 'query-string';
import React, { Component } from "react";
import ReactPixel from 'react-facebook-pixel';
import "../../css/Home.css";
import { examForRegion, regionForDomain } from '../../Shared/locations';
import HomeAsSeenIn from "./HomeAsSeenIn";
import HomeBonus from "./HomeBonus";
import HomeDan from "./HomeDan";
import HomeDiscover from "./HomeDiscover";
import HomeFooter from "./HomeFooter";
import HomeHero from "./HomeHero";
import HomeStudents from "./HomeStudents";
import HomeVenues from "./HomeVenues";
import RegistrationForm from "./RegistrationForm";


class Home extends Component {
  constructor(props) {
    let qsparams = queryString.parse(props.location.search)
    super(props);
    this.debugMode = qsparams.debug
    const region = props.match.params.region || qsparams.region || regionForDomain()
    this.state = {
      region: region ? region.toUpperCase() : undefined,
      exam: examForRegion(region),
    }
  }

  componentDidMount() {
    ReactPixel.pageView();
  }

  onRegionChange = (region) => {
    this.setState({ region: region, exam: examForRegion(region) })
  }

  render() {
    let params = queryString.parse(this.props.location.search)

    return (
      <div className="home-page">
        {params.m1_booking_user
          ?  <RegistrationForm region={this.state.region} exam={this.state.exam} onRegionChange={this.onRegionChange} {...this.props} />
          : <>
            <HomeHero region={this.state.region} exam={this.state.exam}  onRegionChange={this.onRegionChange} {...this.props} />
            <HomeDiscover region={this.state.region} exam={this.state.exam} />
            <HomeAsSeenIn region={this.state.region} exam={this.state.exam} />

            {!params.hs && <HomeStudents region={this.state.region} exam={this.state.exam} />}
            {!params.hb && <HomeBonus region={this.state.region} exam={this.state.exam} />}
            {!params.hd && <HomeDan  region={this.state.region} exam={this.state.exam} />}
            <HomeVenues  region={this.state.region} exam={this.state.exam} />
            <HomeFooter  region={this.state.region} exam={this.state.exam} />
          </>}


      </div>
    );
  }
}
export default Home;

import React, { Component } from "react";

class HomeFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (
      <section className="footer">
        <div className="container">
          <h2>Discover an easier way to succeed in {this.state.exam}</h2>
          <div className="reserve-section">
            <a href="#intro-form" className="reserve-orange">
              Book Your Place now
            </a>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeFooter;

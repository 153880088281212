import request from "./axios";

function registerForSeminar(params) {
  return request({
    method: "post",
    url: `/seminar_registrations`,
    data: params
  })
}

function getWebinarInfo(id) {
  return request({
    method: "get",
    url: `/seminar_registrations/webinar_info?id=${id}`
  })
}

const RegistrationService = {
  registerForSeminar, getWebinarInfo
};

export default RegistrationService;


function regionForDomain() {
  const domains ={
    'www.vceseminar.com.au': 'VIC',
    'vceseminar.com.au': 'VIC',
    'www.wacesuccess.com.au': 'WA',
    'wacesuccess.com.au': 'WA',
    'www.hscsuccess.com.au': 'NSW',
    'hscsuccess.com.au': 'NSW',
    'www.sacesuccess.com.au': 'SA',
    'sacesuccess.com.au': 'SA',
    'www.qcesuccess.com.au': 'QLD',
    'qcesuccess.com.au': 'QLD',
    'www.tcesuccess.com.au': 'TAS',
    'tcesuccess.com.au': 'TAS',
    'www.ntcetsuccess.com.au': 'NT',
    'ntcetsuccess.com.au': 'NT',
    'www.scsuccess.com.au': 'ACT',
    'scsuccess.com.au': 'ACT'
  }
  return domains[window.location.hostname]
}

function examForRegion(region) {
  if (!region || region === '') return 'ATAR'
  const domains ={
    'VIC': 'VCE',
    'WA': 'WACE',
    'NSW': 'HSC',
    'SA': 'SACE',
    'QLD': 'QACE',
    'NT': 'NTCET',
    'TAS': 'TCE',
    'ACT': 'SSC',
  }
  return domains[region ? region.toUpperCase() : 'NOPE']
}


function timezoneForRegion(region) {
  const tzs ={
    'NSW': 'Australia/Sydney',
    'ACT': 'Australia/Canberra',
    'WA': 'Australia/Perth',
    'NT': 'Australia/Darwin',
    'TAS': 'Australia/Hobart',
    'VIC': 'Australia/Melbourne',
    'SA': 'Australia/Adelaide',
    'QLD': 'Australia/Brisbane',
  }
  return tzs[region ? region.toUpperCase() : 'NOPE']
}

function m1PhoneForRegion(region) {
  const numbers ={
    'NSW': '02 8294 7595',
    'ACT': '02 6188 7040',
    'WA': '08 6244 5999',
    'NT': '08 7228 0257',
    'TAS': '08 7228 0257',
    'VIC': '03 9531 0531',
    'SA': '08 7228 0257',
    'QLD': '07 3184 4317',
  }
  return numbers[region ? region.toUpperCase() : 'NOPE']
}

export { regionForDomain, examForRegion, timezoneForRegion, m1PhoneForRegion }


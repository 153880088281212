import React, { Component } from "react";


class HomeDiscover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (
      <section className="discover">
        <div className="container">
          <div className="row ">
            <div className="col-sm-12">
              <h2 style={{marginBottom: 0}}>For students in Year 9, 10, 11 and 12 you will discover ...</h2>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      The single biggest mistake students make during study
                      time… and why it's so important you don't fall into this
                      trap too.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      The 7 key strategies used by top-performing {this.state.exam} students
                      to beat other students and achieve top ATAR scores.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      The 5 Memory Principles you can use to learn and recall
                      information for any subject… from facts and data,
                      definitions, formulae and lists through to English quotes
                      and foreign languages.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      2 powerful yet simple effects discovered by researchers
                      into the human brain which you can use to shorten your
                      study time, and still get more done than ever before…
                      effects which most ordinary students don't even know
                      exist!
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      How to stop procrastinating in 10 seconds… we've all done
                      it but now you can eliminate this time waster and get to
                      work on that crucial homework assignment.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fa fa-check" />
                    </td>
                    <td className="bullet">
                      How to get motivated to study... even if you really
                      couldn't be bothered!
                    </td>
                  </tr>

                </tbody>
              </table>
              <h3 className="subhead">And much more!</h3>

              <div className="reserve-section">
                <a href="#intro-form" className="reserve-orange">
                  Book Your Place now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeDiscover;

import { ErrorMessage, Field, Form, Formik } from "formik";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from "moment";
import "moment-timezone";
import queryString from 'query-string';
import React, { Component } from "react";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import history from "../../history";
import "../../Shared/DateTime.css";
import { timezoneForRegion } from "../../Shared/locations";
import Analytics from '../../utils/Analytics';
import JSONDebugger from '../../utils/JSONDebugger';
import SeminarsService from "../../utils/SeminarsService";

class RegistrationForm extends Component {
  constructor(props) {
    let params = queryString.parse(props.location.search)
    super(props);
    this.debugMode = params.debug
    this.state = {
      region: props.region,
      exam: props.exam,
      email: params.email || "",
      student_first_name: params.student_first_name || "",
      first_name: params.first_name || "",
      mobile: params.mobile || "",
      seminar_id: params.seminar_id || null,
      seminars: undefined,
      utm_source: props.utm_source || params.utm_source || "",
      utm_medium: props.utm_medium || params.utm_medium || "",
      utm_campaign: props.utm_campaign || params.utm_campaign || "",
      utm_content: props.utm_content || params.utm_content || "",
      utm_term: props.utm_term || params.utm_term || "",
      m1_booking_user: params.m1_booking_user,
      booking_from: params.booking_from,
      business_stream: params.business_stream,
      lead_id: params.lead_id,
      redirectUrl: params.redirect_url
    };
  }

  componentDidMount() {
    this.getSeminars(this.props.region);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region) {
      this.setState({ region: this.props.region, exam: this.props.exam }, () => {
        this.getSeminars(this.props.region)
      });
    }
  }

  getSeminars(region) {
    if (!region || region === '') {
      this.setState({ seminars: undefined });
    } else {
      SeminarsService.openSeminars(region, this.state.m1_booking_user).then(seminars => {
        seminars.forEach((s) => {
          s.at = moment(s.at).tz(timezoneForRegion(this.state.region))
        })
        this.setState({ seminars: seminars });
      });
    }
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  setRegion = (newRegion) => {
    this.props.onRegionChange(newRegion)
  }

  render() {
    const SeminarSelectOptionsList = () => {
      if (!this.state.seminars) return []
      const options = []

      const sortedSeminars = this.state.seminars.filter((s) => s.region !== 'ZOOM_WEBINAR').sort((a, b) => { return new Date(a.at) - new Date(b.at) })
      const sortedWebinars = this.state.seminars.filter((s) => s.region === 'ZOOM_WEBINAR').sort((a, b) => { return new Date(a.at) - new Date(b.at) })

      const hasSeminars  = sortedSeminars.length > 0 || sortedWebinars.length > 0
      if (hasSeminars) options.push(<option key='ph' disabled value="">Select your event</option>)

      if (sortedSeminars.length > 0) {
        const sorted = []
        sortedSeminars.forEach((seminar) => { sorted.push(<option key={seminar.id} value={seminar.id}>{`${seminar.location}, ${moment(seminar.at).format("ddd Do MMM h:mma")}`}</option>) })
        options.push(<optgroup label="Seminars in Your Area">{sorted}</optgroup>)
      }

      if (sortedWebinars.length > 0) {
        const sorted = []
        sortedWebinars.forEach((seminar) => {
          sorted.push(<option key={seminar.id} value={seminar.id}>{`${seminar.location}, ${moment(seminar.at).tz(timezoneForRegion(this.state.region)).format("ddd Do MMM h:mma")}`}</option>)
        })
        options.push(<optgroup label="Live Webinars">{sorted}</optgroup>)
      }

      if (!hasSeminars) {
        options.push(<option key="1-on-1" value="1-on-1">Attend a 1 on 1 video session</option>)
      }
      return options
    }

    const validationSchema = Yup.object().shape({
      region: Yup.string()
        .required("is required"),
      first_name: Yup.string()
        .max(30, "maximum 30 characters")
        .required("we need the parent's first name"),
      email: Yup.string()
        .max(100, "maximum 100 characters")
        .email("doesn't look like a proper email address")
        .required("is required"),
      mobile: Yup.string()
        .matches(/04\d\d \d\d\d \d\d\d/, "must be an Australian mobile number")
        .required("is required")
        .test('is-valid', 'must be an Australian mobile number', value => {
          if (!value) return false
          let phoneNumber = parsePhoneNumberFromString(value, 'AU')
          if (!phoneNumber) return false
          return phoneNumber.isValid()
        }),
      student_first_name: Yup.string()
        .max(50, "maximum 50 characters")
        .required("we need the student's first name"),
      seminar_id: Yup.string().required("must pick an option")
    });

    return (
      <React.Fragment>
        <div id="intro-form" className="intro-form">
          <h3 className="subheading xvisible-sm">
            Get your FREE Family Pass
          </h3>
          <h1 className="xhidden-sm">FREE Family Pass</h1>
          <p className="subheading xvisible-sm" style={{ fontSize: 20 }}>
            In 60 minutes, discover the {this.state.exam} success strategies that have
            helped 63% of our students score an ATAR of 90 and above.
        </p>
        <h3 className="subheading xhidden-sm">
          limited availability
        </h3>
          {/* <h3>FREE Online Coaching Session</h3> */}
          <Formik
            initialValues={{
              region: this.state.region,
              email: this.state.email || "",
              first_name: this.state.first_name || "",
              mobile: this.state.mobile || "",
              student_first_name: this.state.student_first_name || "",
              seminar_id: this.state.seminar_id || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              let data = {
                utm_source: this.state.utm_source || "",
                utm_medium: this.state.utm_medium || "",
                utm_campaign: this.state.utm_campaign || "",
                utm_content: this.state.utm_content || "",
                utm_term: this.state.utm_term || "",
                m1_booking_user: this.state.m1_booking_user,
                booking_from: this.state.booking_from,
                business_stream: this.state.business_stream,
                lead_id: this.state.lead_id,
              }

              Analytics.set({ userId: values.email })
              Analytics.event({ category: 'Form', action: 'Submitted Form', label: 'Valid' })

              data = Object.assign(data, values)
              SeminarsService.register(data)
                .then(data => {
                  data.redirectUrl = this.state.redirectUrl
                  if (data.deal_type === "seminar") {
                    history.push("/thankyou", data);
                  }
                  if (data.deal_type === "trial_coaching_session") {
                    data.booking_from = 'web'
                    history.push("/booking", data);
                  }
                })
                .catch(error => {
                  let errors = {};
                  Object.keys(error.data).map(
                    key => (errors[key] = error.data[key].join(", "))
                  );
                  setErrors(errors);
                  setSubmitting(false);
                });
            }}
            render={({
              values,
              errors,
              touched,
              isSubmitting,
              validateForm,
              setFieldValue
            }) => (
                <Form noValidate>
                  {!values.region ?
                    <div
                      className={`form-group ${errors.region &&
                        touched.region &&
                        "has-error"}`}
                    >
                      <Field component="select" name="region" className="form-control" onChange={(e) => {
                        setFieldValue('region', e.target.value)
                        setFieldValue('seminar_id', '')
                        this.setRegion(e.target.value)
                      }}>
                        <option value="">-- where are you located --</option>
                        <option value="ACT">Australian Capital Territory (SSC)</option>
                        <option value="NSW">New South Wales (HSC)</option>
                        <option value="NT">Northern Territory (NTCET)</option>
                        <option value="QLD">Queensland (QCE)</option>
                        <option value="SA">South Australia (SACE)</option>
                        <option value="TAS">Tasmania (TCE)</option>
                        <option value="VIC">Victoria (VCE)</option>
                        <option value="WA">Western Australia (WACE)</option>
                      </Field>
                    </div>
                    : <input type="hidden" id="region" name="region" value={values.region} />}

                  {values.region && <>
                  <div
                    className={`form-group ${errors.student_first_name &&
                      touched.student_first_name &&
                      "has-error"}`}
                  >
                    <Field
                      type="text"
                      name="student_first_name"
                      className="form-control"
                      placeholder="Student first name"
                    />
                    <ErrorMessage name='student_first_name' component="span" className="help-block" />
                  </div>

                  <div
                    className={`form-group ${errors.first_name &&
                      touched.first_name &&
                      "has-error"}`}
                  >
                    <Field
                      type="text"
                      name="first_name"
                      className="form-control"
                      placeholder="Parent first name"
                    />
                    <ErrorMessage name='first_name' component="span" className="help-block" />
                  </div>

                  <div
                    className={`form-group ${errors.email &&
                      touched.email &&
                      "has-error"}`}
                  >
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Parent email address"
                    />
                    <ErrorMessage name='email' component="span" className="help-block" />
                  </div>

                  <div
                    className={`form-group ${errors.mobile &&
                      touched.mobile &&
                      "has-error"}`}
                  >
                    <Field
                      name="mobile"
                      render={({ field }) => {
                        return (
                          <InputMask
                            mask="9999 999 999"
                            {...field}
                            className="form-control"
                            placeholder="Parent mobile number"
                          />
                        );
                      }}
                    />
                    <ErrorMessage name='mobile' component="span" className="help-block" />
                  </div>

                  <div
                    className={`form-group ${errors.seminar_id &&
                      touched.seminar_id &&
                      "has-error"}`}
                  >
                    <Field className="form-control" name="seminar_id" component="select" placeholder="Select your seminar">
                      <SeminarSelectOptionsList />
                    </Field>
                  </div>

                  <div className="reserve-section">
                    <button
                      onClick={() =>
                        validateForm().then((response) => {
                          if (Object.keys(response).length > 0) {
                            Analytics.event({ category: 'Form', action: 'Submitted Form', label: 'Invalid' })
                          }
                        })
                      }
                      className="reserve-orange"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Book Your Place Now
                </button>
                <div className="confidential">
                  All Information Confidential, No Spam
                </div>
                  </div>
                  </>}
                </Form>
              )}
          />
          {this.debugMode ? (<JSONDebugger json={this.state} />) : (<span />)}
        </div>
      </React.Fragment >);
  }
}
export default RegistrationForm;

import request from "./axios";

function freeSlots(params) {
  return request({
    method: "get",
    url: `/slots`,
    params: params
  })
}

function bookTrialCoachingSession(params) {
  return request({
    method: "post",
    url: `/trial_coaching_sessions/book`,
    data: { trial_coaching_session: params }
  })
}

function resheduleTCSAsNewDeal(params) {
  return request({
    method: "post",
    url: `/trial_coaching_sessions/reschedule_as_new_deal`,
    data: { trial_coaching_session: params }
  })
}

const BookingService = {
  freeSlots, bookTrialCoachingSession, resheduleTCSAsNewDeal
};

export default BookingService;

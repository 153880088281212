import queryString from 'query-string';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import history from '../history';


function init() {
    if (window.location.hostname === 'localhost') return
    let params = queryString.parse(window.location.search)
    ReactGA.initialize('UA-18757149-5', { gaOptions: { userId: params.email } })
    ReactGA.set({ dimension1: process.env.BRANCH || 'master' });
    ReactGA.pageview(window.location.pathname + window.location.search);

    history.listen((location, action) => {
      ReactGA.set({ page: location.pathname, dimension1: process.env.BRANCH || 'master' });
      ReactGA.pageview(location.pathname);
    });

    const tagManagerArgs = { gtmId: 'GTM-NQS9SB4' }
    TagManager.initialize(tagManagerArgs)

    const options = { autoConfig: true, debug: false };
    if (process.env.REACT_APP_PIXEL) ReactPixel.init(process.env.REACT_APP_PIXEL, { }, options);
  }

function set(params) {
    if (window.location.hostname === 'localhost') return
    ReactGA.set(params)
}

function event(params) {
    if (window.location.hostname === 'localhost') return
    ReactGA.event(params)
}

const Analytics = {
  init, set, event
};

export default Analytics;

import React, { Component } from "react";


class HomeDanWistia extends Component {
  render() {
    return (

      <div className='wistia'><div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0',position:'relative'}}><div className="wistia_responsive_wrapper" style={{height:'100%', left:'0',position:'absolute',top:'0',width:'100%'}}><div className="wistia_embed wistia_async_10yi6jknii videoFoam=true" style={{height:'100%',width:'100%'}}> </div></div></div></div>

    );
  }
}
export default HomeDanWistia;

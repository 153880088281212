import React, { Component } from "react";
import quoteClose from "../../assets/img/pictures/quotes-close.png";
import quoteOpen from "../../assets/img/pictures/quotes-open.png";
import testimonialBrendan from "../../assets/img/pictures/testimonial-brendan.png";
import testimonialChris from "../../assets/img/pictures/testimonial-chris.png";
import testimonialChristine from "../../assets/img/pictures/testimonial-christine.png";



class HomeStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (

      <section className="students">
        <div className="container">
          <h2>What our students have to say</h2>
          <div className="row row-eq-height ">
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <div className="person-pic">
                  <img src={testimonialBrendan} alt="" />
                </div>
                <img src={quoteOpen} alt="" />
                <p>
                  Excellent! Not only did I enjoy every minute of it, but
                  since I have completed this seminar - my marks have risen by
                  at least a whole grade
                  </p>
                <div className="clearfix">
                  <img src={quoteClose} className="pull-right" alt="" />
                </div>
                <div>
                  <h3 className="name">Brendan Eames-Mayer</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <div className="person-pic">
                  <img src={testimonialChristine} alt="" />
                </div>
                <img src={quoteOpen} alt="" />
                <p>
                  I picked up so many new skills at this seminar. It was
                  fantastic! It was practical, involved the students and it
                  taught us skills that we will sustain over many years to
                  come. Thanks!
                  </p>
                <div className="clearfix">
                  <img src={quoteClose} className="pull-right" alt="" />
                </div>
                <div>
                  <h3 className="name">Christine Diamond</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <div className="person-pic">
                  <img src={testimonialChris} alt="" />
                </div>
                <img src={quoteOpen} alt="" />
                <p>
                  At the start of HSC, I did not know how to approach my
                  studies. After this, I realised there was an easy way to
                  study. I became more confident and achieved an ATAR of 96.0
                  </p>
                <div className="clearfix">
                  <img src={quoteClose} className="pull-right" alt="" />
                </div>
                <div>
                  <h3 className="name">Chris Macdonald</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="reserve-section">
            <a href="#intro-form" className="reserve-orange">
              Book Your Place now
              </a>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeStudents;

import axios from 'axios';
import history from "../history";

/**
 * Create an Axios Client with defaults
 */
const client = function(options) {
    console.log(process.env.REACT_APP_API_URL)
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })
}

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
  const onSuccess = function(response) {
    console.debug('Request Successful!', response);
    return response.data;
  }

  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:',  error.response.status);
      console.error('Data:',    error.response.data);
      console.error('Headers:', error.response.headers);

      if (error.response.status===401) { history.replace("/") } } else { console.error('Error Message:', error.message) }

    return Promise.reject(error.response || error.message);
  }

  return client()(options)
            .then(onSuccess)
            .catch(onError);
}

export default request;
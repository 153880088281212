import React, { Component } from "react";


class HomeVenues extends Component {
  render() {
    return null
    return (

      <section className="venues">
        <div className="container">
          <h2>Venues</h2>
          {/* <div className="row">
            <div className="col-sm-6">
              <div className="location">
                <h3>North Parramatta</h3>
                <h4>Uniting Venues</h4>
                <h4>16 Masons Drive</h4>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="location">
                <h3>Chatswood</h3>
                <h4>Dougherty Community Centre</h4>
                <h4>7 Victor Street</h4>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-sm-6">
              <div className="location">
                <h3>Bondi</h3>
                <h4>Margaret Whitlam Recreation Centre</h4>
                <h4>Bondi Junction</h4>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-sm-6">
              <div className="location">
                <h3>Newcastle</h3>
                <h4>Charlestown Community Centre</h4>
                <h4>Frederick St</h4>
                <h4>Charlestown</h4>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="location">
                <h3>Central Coast</h3>
                <h4>Tuggerah Lakes Community Centre</h4>
                <h4>1 Bay Village Road</h4>
                <h4>Bateau Bay</h4>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-sm-6">
              <div className="location">
                <h3>Wollongong</h3>
                <h4>Berkeley Community Centre</h4>
                <h4>40 Winnima Way</h4>
                <h4>Berkeley</h4>
              </div>
            </div>

          </div>
          <div className="row">

          </div> */}
        </div>
      </section>

    );
  }
}
export default HomeVenues;
